<template>
  <div class="h-auto bg-white w-full pt-6 pb-8 pl-8 pr-8">
    <div class="">
      <div class="font-c8 text-c22 text-left">
        Client
      </div>

      <div class="all mt-12">
        <div class="w-full flex text-6b text-c16 font-c7 text-left mb-6 text-titre">
          <div class="w-1/4">
            Nom et prénoms
          </div>
          <div class="w-1/6 ml-4">
            Téléphone
          </div>
          <div class="w-1/5 ml-4">
            Email
          </div>
          <div class="w-1/6 ml-4">
            Entreprise
          </div>
          <div class="w-1/4 ml-4">
            Adresse de facturation
          </div>
        </div>

        <div class="diviser" />

        <div
          v-if="charge"
          class="flex justify-center mt-16"
        >
          <easy-spinner
            type="dots"
            size="70"
          />
        </div>

        <div
          class="text-center mt-10"
          v-if="tracker.length === 0"
        >
          Aucun client pour le moment
        </div>

        <div
          v-for="(item, index) in tracker"
          :key="index"
          class="mt-5"
        >
          <div
            v-if="index > 0"
            class="diviser mt-5"
          />
          <div>
            <liste
              class="mt-5"
              :item="item"
            />
          </div>
        </div>
      </div>
    </div>

    <!--    <div class="flex mt-10 justify-end pb-16 pr-10">-->
    <!--      <div class="w-1/3 flex items-center justify-end">-->
    <!--        <div class="text-c16 font-c6">-->
    <!--          Page {{ page }} sur {{ totalPage }}-->
    <!--        </div>-->
    <!--        <icon-->
    <!--            :data="icons.prev"-->
    <!--            height="50"-->
    <!--            width="50"-->
    <!--            class="icone cursor-pointer ml-4"-->
    <!--            original-->
    <!--            @click="prev"-->

    <!--        />-->
    <!--        <icon-->
    <!--            :data="icons.next"-->
    <!--            height="50"-->
    <!--            width="50"-->
    <!--            class="icone cursor-pointer ml-4"-->
    <!--            original-->
    <!--            @click="next"-->
    <!--        />-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>

<script>
import arrow from '../../../assets/icons/arrow.svg'
import agenda from '../../../assets/icons/agenda.svg'
import down from '../../../assets/icons/down.svg'
import prev from '../../../assets/icons/prev.svg'
import next from '../../../assets/icons/next.svg'
import liste from './liste'
import apiroutes from '../../../router/api-routes'
import http from '../../../plugins/https'

export default {
  name: "Index",
  components: {
    liste
  },

  props: {
    search: {
      type: String,
      default: null
    },
    filtrage: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      icons: {
        arrow,
        agenda,
        down,
        prev,
        next
      },
      client: [],
      charge: true,
      page: 1,
      limite: 20,
      total: 0,
      totalPage: 0,
      devisPaginate: [],
      temoin: null,
      dataClient: [],
      company: null
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    tracker: function () {
      let retour = this.dataClient

      if (this.search !== null && this.search !== '') {
          const type = this.search.toLowerCase()
          retour = retour.filter((item) => {
            if (((item.fullname).normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(type.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1 ||
                ((item.phone).normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(type.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1 ||
                (item.entreprise_name.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(type.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1 ||
                (item.email.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(type.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1) {
              return item
            }
          })
      }

      return retour
    }
  },

  created () {
    this.company = this.$store.getters.trackCompany
    this.fullClient()
  },

  methods: {
    prev () {
      if (this.page > 1) {
        this.page = this.page - 1
        this.fullClient()
      }
    },

    next () {
      if (this.totalPage > this.page) {
        this.page = this.page + 1
        this.fullClient()
      }
    },

    activeCharge(value){
      this.charge = value
    },

   fullClient () {
      http.post(apiroutes.baseURL + apiroutes.allClient,{
        id: this.company.id,
        type: 'CLIENT'
      })
          .then(response => {
            this.dataClient = response
            // console.log(response)
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            // console.log(error)
          })
   }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.filtr{
  background: rgba(242, 245, 249, 0.5);
  border: 0.5px solid #C4C4C4;
  box-sizing: border-box;
  border-radius: 5px;
  height: 36.39px;
}
.compFiltre{
  width: auto;
  min-width: 9.1%;
}
.diviser {
  height: 0.5px;
  background-color: #DDDDDD;
}
</style>
